import './index.scss'

const TextBalloon = ({ children, text, left = false, rigth = false, top = false }) => {
    return (
        <div className={`TextBalloon ${(left && 'leftContent') || (rigth && 'rightContent')}`}>
            {children}
            <div className='BalloonContent'>
                <div className="content shadow">
                    <p>{text}</p>
                </div>
            </div>
        </div>
    )
}

export default TextBalloon