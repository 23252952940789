import { Link } from 'react-router-dom'
import './index.scss'

const Button = ({ children, className = '', id = '', link = false, target = '', to = '' }) => {
    return (!link
        ? <button className={`Button ${className}`} id={id}>
            <div className="container text">
                {children}
            </div>
        </button>
        : <Link to={to} target={target} className={`Button ${className}`} id={id}>
            <div className="container text">
                {children}
            </div>
        </Link>
    )
}

export default Button