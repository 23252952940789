// SYTLES
import './index.scss'

// REACT
import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"

// API
import { getAPI } from "../../../api"

// ICONS
import { IoChevronBack } from 'react-icons/io5'

// COMPONENTS
import TextBalloon from '../../../components/TextBalloon'
import Button from '../../../components/Button'

// SVG
import LogoAD_4 from '../../../svg/LogoAD_4'

const prazoData = (afterDate, ano, mes, dia) => {
    const dataAtual = new Date()
    if (!afterDate) {
        const prazo = new Date(ano, mes - 1, dia, 23, 59, 59)
        return dataAtual <= prazo
    }
    if (afterDate) {
        const prazo = new Date(ano, mes - 1, dia, 0, 0, 0)
        return dataAtual >= prazo
    }
}

const SubModulo = ({ modulo }) => {
    return modulo.content
        && <ul>
            {modulo.content.map((submodulo, i) => (
                <li key={i}>
                    <p>{submodulo.name}</p>
                    <SubModulo modulo={submodulo} />
                </li>
            ))}
            <br />
        </ul>
}

const Trilha = () => {
    const { TRILHA } = useParams()
    const [trilha, setTrilha] = useState(null)

    useEffect(() => {
        getAPI('/mock/trilhas.json').then(data => setTrilha(data[TRILHA]))
    }, [TRILHA])

    return trilha && <div className="Trilha">
        <header className='shadow'>
            <LogoAD_4 />
        </header>
        <aside className='shadow'>
            <nav>
                <div><Link to='/'><IoChevronBack />Voltar</Link></div>
                <a href="#introducao"><div></div><p>Introdução</p></a>
                {trilha.lotes && trilha.lotes.filter(lote => prazoData(false, lote.inscricaoFim.ano, lote.inscricaoFim.mes, lote.inscricaoFim.dia)).length > 0 && <a href="#inscricoes"><div></div><p>Inscrições</p></a>}
                {trilha.empresas && <a href="#empresas"><div></div><p>Empresas Parceiras</p></a>}
                {trilha.ementa.map((modulo, i) => (
                    <a key={i} href={`#modulo-${i + 1}`}><div></div><p>{modulo.name}</p></a>
                ))}
            </nav>
        </aside>
        <main>
            <div className="main">
                <div className='modulo' id="introducao">
                    <div>
                        <h2>{trilha.title}</h2>
                        <h3>{trilha.subTitle}</h3>
                        <p>{trilha.cargaH} horas de duração | {trilha.modulos} Módulos</p>
                        {trilha.inicioAulas && prazoData(false, trilha.inicioAulas.ano, trilha.inicioAulas.mes, trilha.inicioAulas.dia) && <>
                            <p>A trilha inicia {trilha.inicioAulas.dia < 10 ? <>0{trilha.inicioAulas.dia}</> : <>{trilha.inicioAulas.dia}</>}/{trilha.inicioAulas.mes < 10 ? <>0{trilha.inicioAulas.mes}</> : <>{trilha.inicioAulas.mes}</>}</p>
                        </>}
                    </div>
                </div>
                {trilha.lotes && trilha.lotes.filter(lote => prazoData(false, lote.inscricaoFim.ano, lote.inscricaoFim.mes, lote.inscricaoFim.dia)).length > 0 && <>
                    <div className="modulo" id="inscricoes">
                        <h2>Inscrições</h2>
                        <div className="inscricoes">
                            {trilha.lotes && trilha.lotes.filter(lote => prazoData(false, lote.inscricaoFim.ano, lote.inscricaoFim.mes, lote.inscricaoFim.dia)).map((lote, i) => (
                                <div className='inscricao shadow' key={i}>
                                    <h3>{lote.turma}ª turma</h3>
                                    <p>
                                        {lote.inscricaoInicio.dia < 10 ? <>0{lote.inscricaoInicio.dia}</> : <>{lote.inscricaoInicio.dia}</>}/
                                        {lote.inscricaoInicio.mes < 10 ? <>0{lote.inscricaoInicio.mes}</> : <>{lote.inscricaoInicio.mes}</>}<> a </>
                                        {lote.inscricaoFim.dia < 10 ? <>0{lote.inscricaoFim.dia}</> : <>{lote.inscricaoFim.dia}</>}/
                                        {lote.inscricaoFim.mes < 10 ? <>0{lote.inscricaoFim.mes}</> : <>{lote.inscricaoFim.mes}</>}
                                    </p>
                                    <p className='valorInscricao'>até 10x <span>{lote.valor}</span></p>
                                </div>
                            ))}
                        </div>
                        {trilha.lotes && trilha.lotes.filter(lote => prazoData(false, lote.inscricaoFim.ano, lote.inscricaoFim.mes, lote.inscricaoFim.dia) && lote.inscricaoUrl).map((lote, i) => (
                            <div className="buttonContainer">
                                <Button className='inscreverBtn' key={i} link target='_blank' to={lote.inscricaoUrl}><p>Inscrever-se</p></Button>
                            </div>
                        ))}
                    </div>
                </>}
                {trilha.empresas && <>
                    <div className="modulo" id="empresas">
                        <h2>Empresas Parceiras</h2>
                        <div className="empresas">
                            {trilha.empresas.map((empresa, i) => (
                                <TextBalloon key={i} text={empresa.name}>
                                    <div className="cardEmpresa">
                                        <a href={empresa.url} target="_blank">
                                            <img className='shadow' src={empresa.src} alt={empresa.name} />
                                        </a>
                                    </div>
                                </TextBalloon>
                            ))}
                        </div>
                    </div>
                </>}
                {trilha.ementa.map((modulo, i) => (
                    <div key={i} className='modulo' id={`modulo-${i + 1}`}>
                        <h2>{modulo.name}</h2>
                        {modulo.content && <div className="content">
                            <SubModulo modulo={modulo} />
                        </div>}
                    </div>
                ))}
            </div>
        </main>
    </div>
}

export default Trilha