// ESTILOS
import './index.scss'

// REACT
import { Outlet, Link } from "react-router-dom"
import { useEffect, useRef, useState } from 'react'

// SVG
import LogoAD_1 from '../../svg/LogoAD_1'
import LogoAD_2 from '../../svg/LogoAD_2'
import LogoAD_3 from '../../svg/LogoAD_3'
import Computer from '../../svg/Computer'

// COMPONENTS
import Button from '../../components/Button'

// IMAGES
import banner from '../../img/bannerLandingPagejpg.jpg'
import img_video from '../../img/landing/video.png'
import coding from '../../img/landing/coding.png'
import certificate from '../../img/landing/certificate.png'
import enterprise from '../../img/landing/enterprise.png'

// API
import { getAPI } from '../../api'

// ICONS
import { IoChevronBack, IoChevronForward, IoChevronUp, IoLogoInstagram, IoLogoLinkedin, IoLogoYoutube, IoMail, IoOptions, IoPeople, IoSchool } from 'react-icons/io5'
import { FaBars, FaGears } from 'react-icons/fa6'
import { HiMiniBeaker } from 'react-icons/hi2'

const CarouselServico = ({ data }) => {
    const [slide, setSlide] = useState(null)
    const [previewServico, setPreviewServico] = useState({})
    const [servico, setServico] = useState({})
    const [nextServico, setNextServico] = useState({})
    const slidePreview = useRef(null)
    const slideNext = useRef(null)

    useEffect(() => {
        try {
            setSlide(0)
            setServico(data[0])
            setPreviewServico(data[data.length - 1])
            setNextServico(data[1])

        } catch { }
    }, [data])

    const toPreview = () => {
        slidePreview.current.classList.add('previewAnim')
        setTimeout(() => {
            if (slide === 0) {
                setSlide(data.length - 1)
                setServico(data[data.length - 1])
                setPreviewServico(data[data.length - 2])
                setNextServico(data[0])
            } else {
                setSlide(slide - 1)
                setServico(data[slide - 1])
                setPreviewServico(data[(slide - 2 + data.length) % data.length])
                setNextServico(data[slide])
            }
            slidePreview.current.classList.remove('previewAnim')
        }, 900)
    }

    const toNext = () => {
        slideNext.current.classList.add('nextAnim')
        setTimeout(() => {
            if (slide === data.length - 1) {
                setSlide(0)
                setServico(data[0])
                setPreviewServico(data[data.length - 1])
                setNextServico(data[1])
            } else {
                setSlide(slide + 1)
                setServico(data[slide + 1])
                setPreviewServico(data[slide])
                setNextServico(data[(slide + 2) % data.length])
            }
            slideNext.current.classList.remove('nextAnim')
        }, 900)
    }

    useEffect(() => {
        const interval = setInterval(() => {
            toNext()
        }, 15000)
        return () => clearInterval(interval)
    }, [data, slide])

    return <div className="Carousel">
        <button className='preview' onClick={toPreview}><IoChevronBack /></button>
        <div className="container">
            <div className='slide' ref={slidePreview}>
                <div className="img-container">
                    <img className='background' src={previewServico.image} alt={previewServico.title} />
                </div>
                <div className="content">
                    <h2>{previewServico.title}</h2>
                    {previewServico.subtitle && <h3>{previewServico.subtitle}</h3>}
                    <p>{previewServico.description}</p>
                    {previewServico.url && <Button link target='_blank' to={previewServico.url}>Ver página<IoChevronForward /></Button>}
                </div>
            </div>
            <div className='slide'>
                <div className="img-container">
                    <img className='background' src={servico.image} alt={servico.title} />
                </div>
                <div className="content">
                    <h2>{servico.title}</h2>
                    {servico.subtitle && <h3>{servico.subtitle}</h3>}
                    <p>{servico.description}</p>
                    {servico.url && <Button link target='_blank' to={servico.url}>Ver página<IoChevronForward /></Button>}
                </div>
            </div>
            <div className='slide' ref={slideNext}>
                <div className="img-container">
                    <img className='background' src={nextServico.image} alt={nextServico.title} />
                </div>
                <div className="content">
                    <h2>{nextServico.title}</h2>
                    {nextServico.subtitle && <h3>{nextServico.subtitle}</h3>}
                    <p>{nextServico.description}</p>
                    {nextServico.url && <Button link target='_blank' to={nextServico.url}>Ver página<IoChevronForward /></Button>}
                </div>
            </div>
        </div>
        <button className='next' onClick={toNext}><IoChevronForward /></button>
    </div>
}

const CursoCard = ({ curso, cursoID }) => {
    return <div className="CursoCard shadow">
        <div className="img-container">
            <img src={curso.image} alt={`Logo do treinamento de ${curso.title} ${curso.subTitle}`} />
        </div>
        <div className="container">
            <p>{curso.cargaH}h | {curso.modulos} módulos</p>
            <h3>{curso.title}</h3>
            <p><b>{curso.subTitle}</b></p>
        </div>
        {curso.ementa
            ? <Link to={`trilha/${cursoID}`}><p><b>Ver mais</b> <IoChevronForward /></p></Link>
            : <button><p><b>Ver mais</b> <IoChevronForward /></p></button>
        }
    </div>
}

const LandingPage = () => {
    const [dataLandingPage, setDataLandingPage] = useState(null)
    const [dataServicos, setDataServicos] = useState(null)
    const [dataTrabalhos, setDataTrabalhos] = useState(null)
    const [dataTrilhas, setTrilhas] = useState(null)

    useEffect(() => {
        getAPI('/mock/landingPage.json').then(response => setDataLandingPage(response))
        getAPI('/mock/servicos.json').then(response => setDataServicos(response))
        getAPI('/mock/trabalhos.json').then(response => setDataTrabalhos(response))
        getAPI('/mock/trilhas.json').then(response => setTrilhas(response))
    }, [])

    return <div className='LandingPage'>
        <header className='shadow'>
            <LogoAD_1 />
            <nav className='tablet-hide mobile-hide'>
                <a href="#sobre"><b>Sobre</b></a>
                <a href="#servicos"><b>Serviços</b></a>
                <a href="#trabalhos"><b>Trabalhos</b></a>
                <a href="#treinamentos"><b>Treinamentos</b></a>
                <a href="#contato"><b>Contato</b></a>
                <div className="background mobile-hide tablet-hide"></div>
            </nav>
            <div className='login'>
                <FaBars className='icon desktop-hide' />
                <div className="content">
                    <Link><b>Cadastrar</b></Link>
                    <Button link><b>Entrar</b></Button>
                </div>
            </div>
        </header>
        <div className="scroll">
            <main>
                <div id="bannerPrincipal">
                    <div className="background">
                        <img src={banner} alt="AD Soluções em Tecnologia" />
                    </div>
                    <div className="content">
                        <LogoAD_2 />
                        <h3>{dataLandingPage && dataLandingPage.bannerPrincipal.title}</h3>
                        <p>{dataLandingPage && dataLandingPage.bannerPrincipal.description}</p>
                    </div>
                </div>
                <div id="sobre">
                    <h1>Sobre</h1>
                    <div className="container">
                        <div className="area">
                            <p className='description'>{dataLandingPage && dataLandingPage.sobre.description}</p>
                            <div className="cards">
                                <div className="card">
                                    <IoSchool className='icon' />
                                    <hr />
                                    <p><b>Treinamentos e Cursos</b></p>
                                </div>
                                <div className="card">
                                    <FaGears className='icon' />
                                    <hr />
                                    <p><b>Fábrica de Software</b></p>
                                </div>
                                <div className="card">
                                    <IoPeople className='icon' />
                                    <hr />
                                    <p><b>Outsourcing</b></p>
                                </div>
                                <div className="card">
                                    <HiMiniBeaker className='icon' />
                                    <hr />
                                    <p><b>Innovation Lab</b></p>
                                </div>
                            </div>
                        </div>
                        <div className="imgContainer">
                            <Computer />
                        </div>
                    </div>
                </div>
                <div id="servicos">
                    <h1>Serviços</h1>
                    <CarouselServico data={dataServicos} />
                </div>
                <div id="trabalhos">
                    <h1>Trabalhos</h1>
                    <CarouselServico data={dataTrabalhos} />
                </div>
                <div id="treinamentos">
                    <h1>Treinamentos</h1>
                    <div className="texts">
                        <p className='o-1'>As trilhas da AD Treinamentos se destacam por oferecer uma experiência de aprendizado completa e prática. Aulas ao vivo conduzidas por especialistas da área garantem um acompanhamento personalizado e a oportunidade de tirar dúvidas em tempo real.</p>
                        <img className='o-2' src={img_video} alt="Aulas ao vivo" />
                        <img className='o-4' src={coding} alt="Mão na massa com projetos práticos" />
                        <p className='o-3'>Mas a experiência não para por aí! Para finalizar cada trilha, os alunos são desafiados a desenvolver projetos práticos. Essa abordagem hands-on é fundamental para consolidar os conhecimentos adquiridos e preparar os profissionais para o mercado de trabalho.</p>
                        <p className='o-5'>Um diferencial ainda maior: todos os alunos das trilhas têm a oportunidade de realizar uma certificação internacional ICP Academy. Essa certificação reconhecida mundialmente valida os conhecimentos e habilidades adquiridos durante a trilha, abrindo portas para novas oportunidades de carreira.</p>
                        <img className='o-6' src={certificate} alt="Certificado internacional" />
                        <img className='o-8' src={enterprise} alt="Banco de talentos" />
                        <p className='o-7'>Ao concluírem a trilha, os alunos recebem um certificado que comprova sua participação e desempenho. Além disso, seus portfólios são cadastrados no Banco de Talentos da AD, uma plataforma que conecta talentos com as melhores empresas do mercado. Empresas parceiras monitoram constantemente esse banco de talentos, buscando profissionais qualificados para suas vagas.</p>
                    </div>
                    <div className="cursos">
                        {dataTrilhas && dataTrilhas.map((curso, i) => (
                            <CursoCard curso={curso} cursoID={i} key={i} />
                        ))}
                    </div>
                </div>
            </main>
            <footer id='contato'>
                <div className="links">
                    <div>
                        <h3>Redes Sociais</h3>
                        <a href="https://www.linkedin.com/company/ad-soluções-em-tecnologia/" target='_blank'><IoLogoLinkedin />LinkedIn</a>
                        <a href="https://www.instagram.com/adsolucoestec/" target='_blank'><IoLogoInstagram />Instagram</a>
                        <a href="https://www.youtube.com/@ADSolucoesTecnologia" target='_blank'><IoLogoYoutube />YouTube</a>
                    </div>
                    <div>
                        <h3>Contato</h3>
                        <p><IoMail />contato@adsolucoestecnologia.com.br</p>
                    </div>
                </div>
                <hr />
                <div className='dados'>
                    <div className="container">
                        <p className='text-small'>Copyright &copy; AD Soluções em Tecnologia 2024 - Todos os direitos reservados</p>
                        <p className='text-small'>Rua Doutor Heyde Coelho dos Santos Filho, 130 16404-148 Lins/SP</p>
                        <p className='text-small'>45.083.307/0001-31</p>
                    </div>
                    <p className='text-small'>
                        <a href="http://dpicinato.com" target="_blank" rel="noopener noreferrer">Design por Dimas Picinato</a>
                    </p>
                </div>
            </footer>
        </div>
    </div>
}

export default LandingPage